$(function() {
  $(".js_click_hide").on("click", function() {
    $(this).hide();
  });

  $(".js_submit_answer").on("click", function() {
    const formBlock = $(this).parents("form");
    const score = formBlock.find(".form-check-input").val();
    const comment = formBlock.find("textarea").val();
    const quizId = $("#quiz_id").val();
    const evaluation = { score: score, comment: comment };
    const topBlock = $(this).parents(".collapse");
    topBlock.children().remove();
    topBlock.append('<div class="alert alert-primary">フィーロバックありがとうございました。</div>')

    $.ajax({
      url: `/api/questions/${quizId}/evaluations`,
      type:'POST',
      dataType: 'json'
    })
  });

  $(".js_like").on("click", function() {
    const questionId = $(this).data("question-id");
    $(this).prop("disabled", true);
    $(this).find("i").css("color", "#0d6efd");
    const span = $(this).find("span");
    span.css("color", "#0d6efd");
    span.text( parseInt(span.text())  + 1 );
    
    $.ajax({
      url: `/api/management/questions/${questionId}/like`,
      type:'POST',
      dataType: 'json'
    })
  });

  $(".js_unlike").on("click", function() {
    const questionId = $(this).data("question-id");
    $(this).prop("disabled", true);
    $(this).find("i").css("color", "#0d6efd");
    const span = $(this).find("span");
    span.css("color", "#0d6efd");
    span.text( parseInt(span.text())  + 1 );

    $.ajax({
      url: `/api/management/questions/${questionId}/unlike`,
      type:'POST',
      dataType: 'json'
    })
  });
  
  var pagetop = $('#page-top');
  pagetop.hide();
  $(window).scroll(function () {
     if ($(this).scrollTop() > 100) {
          pagetop.fadeIn();
     } else {
          pagetop.fadeOut();
     }
  });
  pagetop.click(function () {
     $('body, html').animate({ scrollTop: 0 }, 500);
     return false;
  });
})
